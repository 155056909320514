import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { getDeletedUsers } from '../../actions/mobileUser';

const DeletedUsers = (props) => {
  const { getDeletedUsers, deletedUsers } = props;

  useEffect(() => {
    getDeletedUsers();
  }, []);

  return (
    <div className="wrapper">
      <h1>
        GDPR
      </h1>
      <table className="table">
        <thead>
        <tr>
          <th>Email</th>
          <th>Name</th>
          <th>Deleted at</th>
        </tr>
        </thead>
        <tbody>
        {deletedUsers?.map((userData) => (
          <tr key={userData.id}>
            <td>{userData.email}</td>
            <td>{userData.name}</td>
            <td>{moment(userData.deleted_at).format('DD/MM/yyyy HH:mm')}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (store) => ({
  deletedUsers: store.mobileUser.deletedUsers,
});

const mapDispatchToProps = {
  getDeletedUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeletedUsers);