import { blogsAPI } from '../api/blog';
import { actionWrapper } from './actionWrapper';
import { objectToFormData } from '../utils/functions';

export const SET_BLOGS = 'SET_BLOGS';
export const SET_CURRENT_BLOG = 'SET_CURRENT_BLOG';
export const DELETE_BLOG = 'DELETE_BLOG';

export const getBlogs = () => actionWrapper(async () => {
  const blogsData = await blogsAPI.getBlogs();

  return {
    type: SET_BLOGS,
    payload: blogsData,
  };
});

const setCurrentBlog = (blogData) => {
  return {
    type: SET_CURRENT_BLOG,
    payload: blogData,
  };
};

export const getBlogById = (id) => actionWrapper(async () => {
  const blogData = await blogsAPI.getBlogById(id);

  return setCurrentBlog(blogData);
});

export const clearCurrentBlog = () => {
  return setCurrentBlog(null);
};

const setBlogFormData = (blogData) => {
  const formData = new FormData();

  blogData.contents.forEach((content, index) => {
    if (typeof content.value !== 'string') {
      formData.append(`contents.${index}.value`, content.value);
    }
  });

  return objectToFormData(blogData, formData);
};

export const addBlog = (blogData, callback) => actionWrapper(async () => {
  const blogFormData = setBlogFormData(blogData);
  await blogsAPI.addBlog(blogFormData);
  callback();
});

export const  updateBlog = (blogData, callback) => actionWrapper(async () => {
  const blogFormData = setBlogFormData(blogData);
  await blogsAPI.updateBlog(blogFormData);
  callback();
});

export const publishBlog = (id, isPublished) => actionWrapper(async () => {
  await blogsAPI.publishBlog(id, isPublished);
  return getBlogs();
});

export const deleteBlog = (id) => actionWrapper(async () => {
  await blogsAPI.deleteBlog(id);

  return {
    type: DELETE_BLOG,
    payload: id,
  };
});