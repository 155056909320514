import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import BlogForm from '../../components/BlogForm/BlogForm';

import { addBlog } from '../../actions/blog';

const AddBlog = (props) => {
  const { addBlog } = props;
  const navigate = useNavigate();

  const addBlogHandler = (blogData) => {
    addBlog(blogData, () => {
      navigate('/blog');
    });
  };

  return (
    <div className="wrapper">
      <h1>
        Add Blog
      </h1>
      <BlogForm onSave={addBlogHandler} />
    </div>
  );
};

const mapDispatchToProps = {
  addBlog,
};

export default connect(null, mapDispatchToProps)(AddBlog);