import _ from 'lodash';

import { dishAPI } from '../api/dish';
import { actionWrapper } from './actionWrapper';

export const SET_DISHES = 'SET_DISHES';
export const SET_CURRENT_DISH = 'SET_CURRENT_DISH';
export const DELETE_DISH = 'DELETE_DISH';
export const SET_DISH_PRIORITIES = 'SET_DISH_PRIORITIES';

export const getDishes = () => actionWrapper(async () => {
  const dishesData = await dishAPI.getDishes();

  return {
    type: SET_DISHES,
    payload: dishesData,
  };
});

const setCurrentDish = (dishData) => {
  return {
    type: SET_CURRENT_DISH,
    payload: dishData,
  };
};

export const getDishById = (id) => actionWrapper(async () => {
  const dishData = await dishAPI.getDishById(id);

  return setCurrentDish(dishData);
});

export const clearCurrentDish = () => {
  return setCurrentDish(null);
};

const addDishDataForm = (dishData) => {
  const formData = new FormData();

  formData.append('components', JSON.stringify(dishData.components));
  formData.append('excluded_meals', JSON.stringify(dishData.excluded_meals));
  delete dishData.components;
  delete dishData.excluded_meals;

  _.forEach(dishData, (value, field) => {
    formData.append(field, value);
  });

  return formData;
};

export const addDish = (dishData, callback) => actionWrapper(async (dispatch) => {
  
  const formData = addDishDataForm(dishData);
  console.log('===CREATE FORM', dishData);
  await dishAPI.addDish(formData);
  dispatch(getDishes());
  callback?.();
});

export const updateDish = (dishData, callback) => actionWrapper(async (dispatch) => {
  const formData = addDishDataForm(dishData);
  await dishAPI.updateDish(formData);
  dispatch(getDishes());
  callback?.();
});

export const deleteDish = (id) => actionWrapper(async () => {
  await dishAPI.deleteDish(id);

  return {
    type: DELETE_DISH,
    payload: id,
  };
});

export const getDishPriorities = () => actionWrapper(async () => {
  const dishPriorities = await dishAPI.getPriorities();

  return {
    type: SET_DISH_PRIORITIES,
    payload: dishPriorities,
  };
});

export const setDishPriorities = (dishesList) => actionWrapper(async () => {
  const priorities = [];

  dishesList.forEach((dish, index) => {
    priorities.push({ id: dish.id, priority: index });
  });

  await dishAPI.setPriorities({ priorities });
});