import React, { useState, useEffect } from 'react';
// import cn from 'classnames';

import VideoInput from '../VideoInput/VideoInput';
import styles from './BlogForm.module.css';

const DishVideo = (props) => {
  const { 
    initialValues, 
    onChange,
    id,
  } = props;

  const [video, setVideo] = useState(initialValues || '');

  useEffect(() => {
    onChange(video);
  }, [video]);

  return (
    <div className={styles.dishVideoBlock}>
      <VideoInput 
        value={video}
        onChange={setVideo}
        name={`video${id}`}
        label="Upload"
      />
    </div>
  );
};

export default DishVideo;
