import React, { useEffect, useState } from 'react';

import { getFieldFromURLParams, buildSourceURL } from '../../utils/functions';
import styles from './Video.module.css';

const Video = () => {
  const [video, setVideo] = useState('');

  useEffect(() => {
    const video = getFieldFromURLParams('video');
    setVideo(buildSourceURL(video));
  }, []);

  return (
    <div className={styles.wrapper}>
      <video 
        className={styles.video} 
        src={video} 
        controls 
        autoPlay
        muted
      />
    </div>
  );
};

export default Video;
