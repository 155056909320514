import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import { backURL } from '../../config';
import { getBanners, deleteBanner } from '../../actions/banner';
import BannerModal from '../../components/BannerModal/BannerModal';
import styles from './Banners.module.css';

const Banners = (props) => {
  const { 
    getBanners, 
    bannersData,
    deleteBanner,
   } = props;

  const [showModal, setShowModal] = useState(false);
  const [initialValue, setInitialValue] = useState(null);

  useEffect(() => {
    getBanners();
  }, []);

  const openModalHandler = () => {
    setInitialValue(null);
    setShowModal(true);
  };

  const editHandler = (bannerData) => {
    setInitialValue(bannerData);
    setShowModal(true);
  };

  const deleteHandler = (id) => {
    const confirmResult = window.confirm('Are you sure?');

    if (confirmResult) {
      deleteBanner(id);
    }
  };

  return (
    <div className="wrapper">
      <BannerModal 
        key={initialValue?.id}
        isOpen={showModal} 
        onClose={() => setShowModal(false)}
        initialValue={initialValue}
      />
      <div className={styles.tools}>
      <h1>
        Banners
      </h1>
      <div className={styles.buttons}>
        <div className={styles.addDish} onClick={openModalHandler}>
          Add banner
        </div>
      </div>
      </div>
      <table className={cn(styles.table, 'table')}>
        <thead>
        <tr>
          <th>Id</th>
          <th>Image</th>
          <th>Link</th>
          <th>Clicks</th>
          <th>Controls</th>
        </tr>
        </thead>
        <tbody>
        {bannersData?.map((bannerItem) => (
          <tr key={bannerItem.id}>
            <td>
              {bannerItem.id}
            </td>
            <td>
              <img className={styles.bannerImage} src={`${backURL}${bannerItem.image}`} />
            </td>
            <td>
              {bannerItem.link}
            </td>
            <td>
              {bannerItem.clicks || 0}
            </td>
            <td className="controls">
              <button className="button green-button" onClick={() => editHandler(bannerItem)}>
                Edit
              </button>
              <button className="button red-button" onClick={() => deleteHandler(bannerItem.id)}>
                Delete
              </button>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (store) => ({
  bannersData: store.banner.bannersData,
});

const mapDispatchToProps = {
  getBanners,
  deleteBanner,
};

export default connect(mapStateToProps, mapDispatchToProps)(Banners);