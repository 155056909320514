import { mobileUserAPI } from '../api/mobileUser';
import { actionWrapper } from './actionWrapper';
import { runSuccessNotification } from '../utils/functions';

export const SET_MOBILE_USERS = 'SET_MOBILE_USERS';
export const SET_MOBILE_USER_DETAILS = 'SET_MOBILE_USER_DETAILS';
export const SET_MOBILE_CHAT_GPT_DETAILS = 'SET_MOBILE_CHAT_GPT_DETAILS';
export const SET_CHAT_GPT_TESTS = 'SET_CHAT_GPT_TESTS';
export const SET_CHAT_GPT_TEST_DETAILS = 'SET_CHAT_GPT_TEST_DETAILS';
export const SET_DELETED_USERS = 'SET_DELETED_USERS';

export const getMobileUsers = () => actionWrapper(async () => {
  const usersData = await mobileUserAPI.getMobileUsers();
  return {
    type: SET_MOBILE_USERS,
    payload: usersData,
  };
});

export const getMobileUserDetails = (id) => actionWrapper(async () => {
  const userDetailsData = await mobileUserAPI.getMobileUserDetails(id);
  return {
    type: SET_MOBILE_USER_DETAILS,
    payload: userDetailsData,
  };
});

export const getMobileChatGPTDetails = (id) => actionWrapper(async () => {
  const chatGPTDetailsData = await mobileUserAPI.getMobileChatGPTDetails(id);
  return {
    type: SET_MOBILE_CHAT_GPT_DETAILS,
    payload: chatGPTDetailsData,
  };
});

export const addToTest = (id) => actionWrapper(async () => {
  await mobileUserAPI.addToTest(id);
  runSuccessNotification();
});

export const getChatGTPTest = () => actionWrapper(async () => {
  const gptTests = await mobileUserAPI.getChatGPTTest();
  return {
    type: SET_CHAT_GPT_TESTS,
    payload: gptTests,
  };
});

export const getChatGPTTestDetails = (id) => actionWrapper(async () => {
  const testDetailsData = await mobileUserAPI.getChatGPTTestDetails(id);

  return {
    type: SET_CHAT_GPT_TEST_DETAILS,
    payload: testDetailsData,
  };
});

export const saveTest = (testData) => actionWrapper(async (dispatch) => {
  await mobileUserAPI.saveTest(testData);
  dispatch(getChatGPTTestDetails(testData.id));
  runSuccessNotification();
});

export const runTest = (id) => actionWrapper(async (dispatch) => {
  await mobileUserAPI.runTest(id);
  dispatch(getChatGPTTestDetails(id));
  runSuccessNotification();
});

export const clearTestAnswers = (id) => actionWrapper(async (dispatch) => {
  await mobileUserAPI.clearAnswers(id);
  dispatch(getChatGPTTestDetails(id));
  runSuccessNotification();
});

export const getDeletedUsers = () => actionWrapper(async () => {
  const deletedUsers = await mobileUserAPI.getDeletedUsers();
  return { 
    type: SET_DELETED_USERS,
    payload: deletedUsers,
  };
});

