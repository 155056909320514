import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import cn from 'classnames';
// import _ from 'lodash';

import { getAuthors } from '../../actions/author';
import BlogContent from './BlogContent';
import BlogImage from './BlogImage';
import styles from './BlogForm.module.css';

const defaultContent = {
  fakeId: Date.now(),
  type: 'text',
};

const BlogForm = (props) => {
  const {
    initialValues, 
    onSave,
    getAuthors,
    authorsData,
  } = props;

  const [title, setTitle] = useState(initialValues?.title || '');
  const [description, setDescription] = useState(initialValues?.description || '');
  const [contents, setContent] = useState(initialValues?.contents || [defaultContent]);
  const [image, setImage] = useState(initialValues?.image);
  const [author, setAuthor] = useState(initialValues?.author_id || '');

  useEffect(() => {
    getAuthors();
  }, []);

  console.log('===authorsData', authorsData);

  const onSaveHandler = () => {
    const trimmedTitle = title.trim();
    if (!trimmedTitle) {
      return toast.error('Title is required', {
        position: 'bottom-right',
        duration: 3000,
      });
    }
    
    if (!description) {
      return toast.error('Description is required', {
        position: 'bottom-right',
        duration: 3000,
      });
    }
    
    if (!image) {
      return toast.error('Pleas select cover image', {
        position: 'bottom-right',
        duration: 3000,
      });
    }
    
    if (!author) {
      return toast.error('Pleas select author', {
        position: 'bottom-right',
        duration: 3000,
      });
    }

      // Check all data in components
    for (let content of contents) {
      const { value } = content;

      if (!value) {
        return toast.error('You need enter data in content', {
          position: 'bottom-right',
          duration: 3000,
        });
      }
    }

    const blogData = {
      title: trimmedTitle,
      image,
      description,
      contents,
      author_id: author,
    };

    onSave(blogData);
  };

  const onChangeComponentHandler = (contentData, index) => {
    Object.assign(contents[index], contentData);
    // components[index] = componentData;
    setContent([...contents]);
  };

  const addContent = () => {
    const newContent = {
      ...defaultContent,
      fakeId: Date.now(),
    };

    contents.push(newContent);
    setContent([...contents]);
  };

  const deleteContent = (index) => {
    contents.splice(index, 1);
    setContent([...contents]);
  };

  const valueChangeHandler = (setter) => (event) => {
    setter(event.target.value);
  };

  const changeImageHandler = (imageData) => {
    setImage(imageData);
  };

  return (
    <div>
      <label className={styles.row}>
        <span>Title*:</span>
        <input 
          className={styles.input}
          placeholder="Title"
          value={title}
          onChange={valueChangeHandler(setTitle)}
        />
      </label>
      <label className={styles.row}>
        <span>Description*</span>
        <input 
          className={styles.input}
          placeholder="Description"
          value={description}
          onChange={valueChangeHandler(setDescription)}
        />
      </label>
      <label className={styles.row}>
        <span>Author*</span>
        <select 
          className={styles.select}
          onChange={valueChangeHandler(setAuthor)}
          value={author}
        >
          <option value="" disabled>Select author</option>
          {authorsData.map(author => (
            <option key={author.id} value={author.id}>
              {author.name}
            </option>
          ))}
        </select>
      </label>
      <div className={styles.dishImages}>
        <div className={styles.dishImagesTitle}>
          Cover image*
        </div>
        <BlogImage
          initialValues={image}
          onChange={changeImageHandler}
        />
      </div>
      <div className={styles.dishComponentsWrapper}>
        <div className={styles.dishComponents}>Blog Content*</div>
        {contents.map((content, index) => (
          <div className={styles.componentBlock} key={content.fakeId || content.id} >
            <BlogContent 
              initialValues={content}
              onChange={componentData => onChangeComponentHandler(componentData, index)}
              id={content.fakeId || content.id}
            />
            {contents.length > 1 && (
              <button 
                className={cn(styles.saveButton, 'button', 'red-button')} 
                onClick={() => deleteContent(index)}
              >
                Delete content
              </button>
            )}
          </div>
        ))}
        <button 
          className={cn(styles.saveButton, 'button', 'green-button')} 
          onClick={addContent}
        >
          Add content
        </button>
      </div>

      <button 
        className={cn(styles.saveButton, 'button', 'blue-button')} 
        onClick={onSaveHandler}
      >
        Save
      </button>
    </div>
  );
};

const mapStateToProps = (store) => ({
  authorsData: store.author.authorsData,
});

const mapDispatchToProps = {
  getAuthors,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogForm);
