import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { weightUnitLabels } from '../../constants/labels';
import { getMobileChatGPTDetails, addToTest } from '../../actions/mobileUser';
import { countries, physicalActivityLabel, goalLabel} from '../../constants/labels';
import Menus from '../../components/Menus/Menus';

import styles from './MobileChatGPTDetails.module.css';

const MobileChatGPTDetails = (props) => {
  const {
    getMobileChatGPTDetails,
    addToTest,
    chatGPTDetails,
  } = props;

  const { id } = useParams();

  useEffect(() => {
    getMobileChatGPTDetails(id);
  }, []);

  const totalPriceData = useMemo(() => {
    return chatGPTDetails?.chatGPT?.reduce((totalPriceData, chatGPTData) => {
      totalPriceData.price += chatGPTData.price;
      totalPriceData.requests++;
      return totalPriceData;
    }, { price: 0, requests: 0});
  }, [chatGPTDetails]);

  if (!chatGPTDetails) {
    return false;
  }

  return (
    <div className="wrapper">
      <h1>
        Mobile user details
      </h1>
      <div className={styles.chatGPTDetails}>
        <div className={styles.leftPart}>
          <div className={styles.infoRow}>
            Name: {chatGPTDetails.name}
          </div>
          <div className={styles.infoRow}>
            Email: {chatGPTDetails.email}
          </div>
          <div className={styles.infoRow}>
            Country: {countries[chatGPTDetails.country] || '-'}
          </div>
          <div className={styles.infoRow}>
            Gender: {chatGPTDetails.gender ? (chatGPTDetails.gender === 'man' ? 'Male' : 'Female') : '-'}
          </div>
          <div className={styles.infoRow}>
            Date of birth: {chatGPTDetails.birth_date ? moment(chatGPTDetails.birth_date).format('DD/MM/YYYY') : '-'}
          </div>
          <div className={styles.infoRow}>
            Weight: {chatGPTDetails.weight} {weightUnitLabels[chatGPTDetails.weight_unit] || '-'}
          </div>
          <div className={styles.infoRow}>
            Height: {chatGPTDetails.height ? `${chatGPTDetails.height} ${(chatGPTDetails.height_unit === 'cm' ? 'cm' : 'feet')}` : '-'}
          </div>
          <div className={styles.infoRow}>
            Physical activity: {physicalActivityLabel[chatGPTDetails.physical_activity] || '-'}
          </div>
          <div className={styles.infoRow}>
            Exclude meals: {chatGPTDetails.exclude_meals || '-'}
          </div>
          <div className={styles.infoRow}>
            Allergies: {chatGPTDetails.allergies || '-'}
          </div>
          <div className={styles.infoRow}>
            Goal: {goalLabel[chatGPTDetails.goal] || '-'}
          </div>
          <div className={styles.infoRow}>
            Calories: {chatGPTDetails.calories ?  `${chatGPTDetails.calories} kcal` : '-'}
          </div>
          <div className={styles.infoRow}>
            Protein: {chatGPTDetails.protein ?  `${chatGPTDetails.protein} grams` : '-'}
          </div>
          <div className={styles.infoRow}>
            Fat: {chatGPTDetails.fat ?  `${chatGPTDetails.fat} grams` : '-'}
          </div>
          <div className={styles.infoRow}>
            Carbohydrates: {chatGPTDetails.carbohydrates ?  `${chatGPTDetails.carbohydrates} grams` : '-'}
          </div>
          <div className={styles.infoRow}>
            Created at: {chatGPTDetails.created_at ? moment(chatGPTDetails.created_at).format('DD/MM/YYYY') : '-'}
          </div>
          <div className={styles.gptInfo}>
            Days: {chatGPTDetails.days}
          </div>
        </div>
      </div>
      {chatGPTDetails.menus && <Menus menus={chatGPTDetails.menus} />}
      <div className={styles.totalPrice}>
        Total Price: ${totalPriceData.price.toFixed(4)}. Total Requests: {totalPriceData.requests}
      </div>
      {chatGPTDetails.chatGPT.map((chatGPTData) => (
        <div className={styles.chatGPTBlock} key={chatGPTData.id}>
          <div className={styles.gptInfoBlock}>
            <div className={styles.typeTitle}>
              {chatGPTData.type}
            </div>
            <div className={styles.gptInfo}>
              Model: {chatGPTData.model}
            </div>
            <div className={styles.gptInfo}>
              Time: {chatGPTData.time.toFixed(2)} sek
            </div>
            <div className={styles.gptInfo}>
              Input tokens: {chatGPTData.input_tokens}
            </div>
            <div className={styles.gptInfo}>
              Output tokens: {chatGPTData.output_tokens}
            </div>
            <div className={styles.gptInfo}>
              Price: ${chatGPTData.price.toFixed(4)}
            </div>
          </div>
          <div className={styles.prompts}>
            <div className={styles.prompt}>
              {chatGPTData.promptJSON?.map((prompt, index) => (
                <div className={styles.promptBlock} key={index}>
                  <div className={styles.promptRole}>{prompt.role}</div>
                  <div className={styles.promptItem}>{prompt.content}</div>
                </div>
              ))}
            </div>
            <div className={styles.answer}>
              {chatGPTData.answer}
            </div>
          </div>
          <button 
            className="button blue-button" 
            onClick={() => addToTest(chatGPTData.id)}
          >
            Add to test
          </button>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  chatGPTDetails: state.mobileUser.chatGPTDetails,
});

const mapDispatchToProps = {
  getMobileChatGPTDetails,
  addToTest,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileChatGPTDetails);
