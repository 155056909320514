import React, { useState, useEffect, } from 'react';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';

import { settingsNumberFields } from '../../constants/settingFields';
import { getSettings, updateSettings } from '../../actions/settings';
import styles from './Settings.module.css';

const Settings = (props) => {
  const { 
    getSettings, 
    updateSettings,
    settingsData,
  } = props;

  const [settings, setSettings] = useState([]);

  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {
    setSettings(settingsData);
  }, [settingsData]);

  const changeSettingsHandler = (id, value) => {
    const settingIndex = settings.findIndex(setting => setting.id === id);
    settings[settingIndex].value = value;
    setSettings([...settings]);
  };

  const saveSettingData = (id) => {
    const settingToSave = settings.find(setting => setting.id === id);
    updateSettings(settingToSave, () => {
      return toast.success('Settings was saved', {
        position: 'bottom-right',
        duration: 3000,
      });
    });
  };

  const renderInput = (setting) => {
    if (settingsNumberFields[setting.field]) {
      return (
        <input 
          className={styles.input}
          value={setting.value} 
          onChange={({ target }) => changeSettingsHandler(setting.id, target.value)}
        />
      );
    }

    return (
      <textarea 
        className={styles.textarea}
        value={setting.value} 
        onChange={({ target }) => changeSettingsHandler(setting.id, target.value)}
      />
    );
  };

  if (!settings.length) {
    return null;
  }

  return (
    <div className="wrapper">
      <h1>
        Settings
      </h1>
      {settings.map(setting => (
        <div key={setting.id} className={styles.row}>
          <label>
            <span className={styles.labelText}>{setting.label}:</span>
            {renderInput(setting)}
          </label>
          <button 
            className="button green-button" 
            onClick={() => saveSettingData(setting.id)}
          >
            Save
          </button>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (store) => ({
  settingsData: store.settings.settingsData,
});

const mapDispatchToProps = {
  getSettings, 
  updateSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);