import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { weightUnitLabels, heightUnitLabels } from '../../constants/labels';
import { getMobileUserDetails } from '../../actions/mobileUser';
import { countries, physicalActivityLabel, goalLabel} from '../../constants/labels';
import Menus from '../../components/Menus/Menus';

import styles from './MobileUserDetails.module.css';

const MobileUserDetails = (props) => {
  const {
    getMobileUserDetails,
    userDetails,
  } = props;

  const { id } = useParams();
  // const totalNutrients = {};

  useEffect(() => {
    getMobileUserDetails(id);
  }, []);

  if (!userDetails) {
    return false;
  }

  return (
    <div className="wrapper">
      <h1>
        Mobile user details
      </h1>
      <div className={styles.userDetails}>
        <div className={styles.leftPart}>
          <div className={styles.infoRow}>
            Name: {userDetails.name}
          </div>
          <div className={styles.infoRow}>
            Email: {userDetails.email}
          </div>
          <div className={styles.infoRow}>
            Country: {countries[userDetails.country] || '-'}
          </div>
          <div className={styles.infoRow}>
            Gender: {userDetails.gender ? (userDetails.gender === 'man' ? 'Male' : 'Female') : '-'}
          </div>
          <div className={styles.infoRow}>
            Date of birth: {userDetails.birth_date ? moment(userDetails.birth_date).format('DD/MM/YYYY') : '-'}
          </div>
          <div className={styles.infoRow}>
            Weight: {+userDetails.weight?.toFixed(2) || ''} {weightUnitLabels[userDetails.weight_unit] || '-'}
          </div>
          <div className={styles.infoRow}>
            Height: {+userDetails.height?.toFixed(2) || ''} {heightUnitLabels[userDetails.height_unit] || '-'}
          </div>
          <div className={styles.infoRow}>
            Physical activity: {physicalActivityLabel[userDetails.physical_activity] || '-'}
          </div>
          <div className={styles.infoRow}>
            Exclude meals: {userDetails.exclude_meals || '-'}
          </div>
          <div className={styles.infoRow}>
            Allergies: {userDetails.allergies || '-'}
          </div>
          <div className={styles.infoRow}>
            Goal: {goalLabel[userDetails.goal] || '-'}
          </div>
          <div className={styles.infoRow}>
            Calories: {userDetails.calories ?  `${userDetails.calories} kcal` : '-'}
          </div>
          <div className={styles.infoRow}>
            Protein: {userDetails.protein ?  `${userDetails.protein} grams` : '-'}
          </div>
          <div className={styles.infoRow}>
            Fat: {userDetails.fat ?  `${userDetails.fat} grams` : '-'}
          </div>
          <div className={styles.infoRow}>
            Carbohydrates: {userDetails.carbohydrates ?  `${userDetails.carbohydrates} grams` : '-'}
          </div>
          <div className={styles.infoRow}>
            Created at: {userDetails.created_at ? moment(userDetails.created_at).format('DD/MM/YYYY') : '-'}
          </div>
        </div>
      </div>
      {userDetails.menus && <Menus menus={userDetails.menus} />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userDetails: state.mobileUser.mobileUserDetails,
});

const mapDispatchToProps = {
  getMobileUserDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileUserDetails);
