import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import cn from 'classnames';

import { getBlogs, deleteBlog, publishBlog } from '../../actions/blog';
import styles from './Blog.module.css';

const Blog = (props) => {
  const { 
    getBlogs, 
    publishBlog,
    deleteBlog, 
    blogsData, 
  } = props;

  useEffect(() => {
    getBlogs();
  }, []);

  const deleteHandler = (id) => {
    const confirmResult = window.confirm('Are you sure?');

    if (confirmResult) {
      deleteBlog(id);
    }
  };

  const publishHandler = (blog) => {
    const confirmResult = window.confirm('Are you sure?');

    if (confirmResult) {
      publishBlog(blog.id, !blog.is_published);
    }
  };

  const renderPublishButton = (blogData) => {
    if (blogData.is_published) {
      return (
        <button className="button yellow-button" onClick={() => publishHandler(blogData)}>
          Unpublish
        </button>
      );
    }

    return (
      <button className="button blue-button" onClick={() => publishHandler(blogData)}>
        Publish
      </button>
    );
  };

  return (
    <div className="wrapper">
      <div className={styles.tools}>
        <h1>
          Blog Articles
        </h1>
        <a className={styles.addDish} href="/add-blog">
          Add blog
        </a>
      </div>
      <table className={cn(styles.table, 'table')}>
        <thead>
        <tr>
          <th>Title</th>
          <th>Description</th>
          <th>Created at</th>
          <th>Controls</th>
        </tr>
        </thead>
        <tbody>
        {blogsData?.map((blogData) => (
          <tr key={blogData.id}>
            <td>                
              {blogData.title}
            </td>
            <td>
              {blogData.description}
            </td>
            <td>
              {moment(blogData.created_at).format('DD/MM/yyyy HH:mm')}
            </td>
            <td className="controls">
              <Link to={`/edit-blog/${blogData.id}`} className="button green-button">
                Edit
              </Link>
              {renderPublishButton(blogData)}
              <button className="button red-button" onClick={() => deleteHandler(blogData.id)}>
                Delete
              </button>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (store) => ({
  blogsData: store.blog.blogsData,
});

const mapDispatchToProps = {
  getBlogs,
  publishBlog,
  deleteBlog,
};

export default connect(mapStateToProps, mapDispatchToProps)(Blog);