import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { getHelp } from '../../actions/help';

import styles from './Help.module.css';

const Help = (props) => {
  const { getHelp, helpData } = props;

  useEffect(() => {
    getHelp();
  }, []);

  return (
    <div className="wrapper">
      <h1>
        Help
      </h1>
      <table className="table">
        <thead>
        <tr>
          <th>Email</th>
          <th>Name</th>
          <th>Message</th>
          <th>Created at</th>
        </tr>
        </thead>
        <tbody>
        {helpData?.map((help) => (
          <tr key={help.id}>
            <td>
              <Link className={styles.link} to={`/mobile-user/${help.user_id}`} target="_blank">
                {help.email}
              </Link>
            </td>
            <td>{help.name}</td>
            <td>{help.message}</td>
            <td>{moment(help.created_at).format('DD/MM/yyyy HH:mm')}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (store) => ({
  helpData: store.help.helpData,
});

const mapDispatchToProps = {
  getHelp,
};

export default connect(mapStateToProps, mapDispatchToProps)(Help);