import { applyMiddleware, combineReducers, createStore } from 'redux';
import { thunk } from 'redux-thunk';

import auth from './auth';
import preloader from './preloader';
import questioner from './questioner';
import ingredient from './ingredient';
import allergy from './allergy';
import blog from './blog';
import author from './author';
import banner from './banner';
import dish from './dish';
import mobileUser from './mobileUser';
import help from './help';
import settings from './settings';

const USER_LOGOUT = 'USER_LOGOUT';

const allReducers = combineReducers({
  auth,
  preloader,
  questioner,
  ingredient,
  allergy,
  blog,
  author,
  banner,
  dish,
  mobileUser,
  help,
  settings,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    state = undefined;
  }

  return allReducers(state, action);
};

let middleware = [thunk];

export default createStore(rootReducer, applyMiddleware(...middleware));
