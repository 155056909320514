// import toast from 'react-hot-toast';

import { authorAPI } from '../api/author';
import { actionWrapper } from './actionWrapper';
import { objectToFormData } from '../utils/functions';

export const SET_AUTHORS = 'SET_AUTHORS';
export const DELETE_AUTHOR = 'DELETE_AUTHOR';

export const getAuthors = () => actionWrapper(async () => {
  const authorsData = await authorAPI.getAuthors();

  return {
    type: SET_AUTHORS,
    payload: authorsData,
  };
});

export const addAuthor = (authorData) => actionWrapper(async (dispatch) => {
  const authorFormData = objectToFormData(authorData);
  await authorAPI.addAuthor(authorFormData);
  dispatch(getAuthors());
});

export const updateAuthor = (authorData) => actionWrapper(async (dispatch) => {
  const authorFormData = objectToFormData(authorData);
  await authorAPI.updateAuthor(authorFormData);
  dispatch(getAuthors());
});

export const deleteAuthor = (id) => actionWrapper(async () => {
  await authorAPI.deleteAuthor(id);

  return {
    type: DELETE_AUTHOR,
    payload: id,
  };
});
