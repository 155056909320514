import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import { backURL } from '../../config';
import { getAuthors, deleteAuthor } from '../../actions/author';
import AuthorModal from '../../components/AuthorModal/AuthorModal';
import styles from './Authors.module.css';

const Authors = (props) => {
  const { 
    getAuthors, 
    authorsData,
    deleteAuthor,
   } = props;

  const [showModal, setShowModal] = useState(false);
  const [initialValue, setInitialValue] = useState(null);

  useEffect(() => {
    getAuthors();
  }, []);

  const openModalHandler = () => {
    setInitialValue(null);
    setShowModal(true);
  };

  const editHandler = (authorData) => {
    setInitialValue(authorData);
    setShowModal(true);
  };

  const deleteHandler = (id) => {
    const confirmResult = window.confirm('Are you sure?');

    if (confirmResult) {
      deleteAuthor(id);
    }
  };

  return (
    <div className="wrapper">
      <AuthorModal 
        key={initialValue?.id}
        isOpen={showModal} 
        onClose={() => setShowModal(false)}
        initialValue={initialValue}
      />
      <div className={styles.tools}>
      <h1>
        Authors
      </h1>
      <div className={styles.buttons}>
        <div className={styles.addDish} onClick={openModalHandler}>
          Add author
        </div>
      </div>
      </div>
      <table className={cn(styles.table, 'table')}>
        <thead>
        <tr>
          <th>Avatar</th>
          <th>Name</th>
          <th>Profession</th>
          <th>Controls</th>
        </tr>
        </thead>
        <tbody>
        {authorsData?.map((authorItem) => (
          <tr key={authorItem.id}>
            <td>
              <img className={styles.authorImage} src={`${backURL}${authorItem.image}`} />
            </td>
            <td>
              {authorItem.name}
            </td>
            <td>
              {authorItem.profession}
            </td>
            <td className="controls">
              <button className="button green-button" onClick={() => editHandler(authorItem)}>
                Edit
              </button>
              <button className="button red-button" onClick={() => deleteHandler(authorItem.id)}>
                Delete
              </button>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (store) => ({
  authorsData: store.author.authorsData,
});

const mapDispatchToProps = {
  getAuthors,
  deleteAuthor,
};

export default connect(mapStateToProps, mapDispatchToProps)(Authors);