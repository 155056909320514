import React from 'react';
import ReactQuill from 'react-quill';
// import cn from 'classnames';

// import { arrayToMap } from '../../utils/functions';
import BlogImage from './BlogImage';
import BlogVideo from './BlogVideo';
import styles from './BlogForm.module.css';
import 'react-quill/dist/quill.snow.css';

export const TEXT_TYPE = 'text';
export const VIDEO_TYPE = 'video';
export const IMAGE_TYPE = 'image';

const editorModules = {
  toolbar: [
    [{ 'header': [1, 2, 3, false] }],
    ['bold', 'italic', 'underline'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    ['link'],
  ],
};

export default {
  [TEXT_TYPE]: {
    label: 'Text',
    editor: (props) => {
      const { onChange, value } = props;
  
      return (
        <ReactQuill 
          onChange={onChange}
          value={value}
          modules={editorModules} 
          className={styles.editor}  
        />
      );
    }
  },
  [IMAGE_TYPE]: {
    label: 'Image',
    editor: (props) => {
      const { onChange, value, id } = props;
  
      return (
        <BlogImage 
          id={id}
          onChange={onChange} 
          initialValues={value} 
        />
      );
    }
  },
  [VIDEO_TYPE]: {
    label: 'Video',
    editor: (props) => {
      const { onChange, value, id } = props;
  
      return (
        <BlogVideo 
          id={id} 
          onChange={onChange} 
          initialValues={value} 
        />
      );
    }
  },
};