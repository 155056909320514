import { get, getById, postFile, putFile, put, deleteById } from './api';

export const blogsAPI = {
  getBlogs() {
    return get('admin/blog');
  },

  getBlogById(id) {
    return getById('admin/blog', id);
  },

  addBlog(blogData) {
    return postFile('admin/blog', blogData);
  },

  updateBlog(blogData) {
    return putFile('admin/blog', blogData);
  },

  publishBlog(id, isPublished) {
    return put('admin/blog/publish', { id, is_published: isPublished });
  },

  deleteBlog(id) {
    return deleteById('admin/blog', id);
  },
};
