import React, { useState } from 'react';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';

import { addBanner, updateBanner } from '../../actions/banner';
import ImageInput from '../ImageInput/ImageInput';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import styles from './BannerModal.module.css';

const BannerModal = (props) => {
  const { 
    initialValue, 
    isOpen, 
    onClose,
    addBanner,
    updateBanner,
  } = props;

  const [link, setLink] = useState(initialValue?.link || '');
  const [image, setImage] = useState(initialValue?.image || '');

  const title = initialValue ? 'Edit banner' : 'Add new banner';

  const changeValueHandler = setter => event => {
    setter(event.target.value);
  };

  const onSaveHandler = () => {
    if (!link) {
      return toast.error('Link is required', {
        position: 'bottom-right',
        duration: 3000,
      });
    }

    if (!image) {
      return toast.error('Image is required', {
        position: 'bottom-right',
        duration: 3000,
      }); 
    }

    const bannerData = {
      link,
      image,
    };

    if (initialValue) {
      bannerData.id = initialValue.id;
      updateBanner(bannerData);
    } else {
      addBanner(bannerData);
    }

    onClose();
  };

  return (
    <ModalWrapper 
      isOpen={isOpen} 
      onClose={onClose} 
      onSave={onSaveHandler} title={title}
    >
      <div className={styles.wrapper}>
        <label className={styles.row}>
          <span>Link</span>
          <input 
            className={styles.input} 
            onChange={changeValueHandler(setLink)}
            placeholder="Link"
            value={link}
          />
        </label>
        <label>
          <ImageInput 
            value={image}
            onChange={setImage}
            name="image"
          />
        </label>
      </div>
    </ModalWrapper>
  );
};

const mapDispatchToProps = {
  addBanner,
  updateBanner,
};

export default connect(null, mapDispatchToProps)(BannerModal);