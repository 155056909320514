import React, { useState, useEffect } from 'react';
// import cn from 'classnames';

import ImageInput from '../ImageInput/ImageInput';
import styles from './DishForm.module.css';

const DishImage = (props) => {
  const { 
    initialValues, 
    onChange,
  } = props;

  const [image, setImage] = useState(initialValues || '');
  // const [firstRun, setFirstRun] = useState(true);

  // console.log('===IMAGEE', firstRun, image);
  console.log('===initialValues', initialValues);

  useEffect(() => {
    onChange(image);
    // if (firstRun) {
    //   setFirstRun(false);
    // } else if (image !== initialValues || image) {
    //   console.log('===USE EFFECT IMAGE', image);
    //   onChange(image !== initialValues);
    // }
  }, [image]);

  return (
    <div className={styles.dishImageBlock}>
      <ImageInput 
        value={image}
        onChange={setImage}
        name="image"
        label="Upload"
      />
    </div>
  );
};

export default DishImage;
