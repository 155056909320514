import toast from 'react-hot-toast';
import _ from 'lodash';

import { 
  unitTypeLabels, 
  UNIT_TYPE_AMOUNT,
  UNIT_TYPE_WITHOUT_AMOUNT,
} from '../constants/unitTypes';

import { backURL } from '../config';

export const getFieldFromURLParams = (field) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(field);
};

export const getUserData = () => {
  const userData = localStorage.getItem('user');

  if (userData) {
    return JSON.parse(userData);
  }

  return null;
};

export const setUserData = (userData) => {
  localStorage.setItem('user', JSON.stringify(userData));
};


export const updateUserData = (userData) => {
  const oldUserData = getUserData();
  const newUserData = {
    ...oldUserData,
    ...userData,
  };
  setUserData(newUserData);
};

export const getIngredientAmountString = (ingredient, amount, required) => {
  if (!ingredient?.unit_type) {
    return '';
  }

  const requiredString = (+required) ? '(required)' : '';

  switch (ingredient.unit_type) {
    case UNIT_TYPE_AMOUNT:
      return `${amount} ${ingredient.name} ${requiredString}`;

    case UNIT_TYPE_WITHOUT_AMOUNT:
      return `${ingredient.name} ${requiredString}`;

    default: {
      const unitType = unitTypeLabels[ingredient.unit_type].toLowerCase();
      return `${amount} ${unitType} of ${ingredient.name} ${requiredString}`;
    }
  }
};

export const arrayToMap = (array) => {
  const map = new Map();

  if (!array?.length) {
    return map;
  }

  array.forEach((data) => {
    map.set(data.id, data);
  });

  return map;
};

export const runSuccessNotification = (message = 'Success') => {
  toast.success(message, {
    position: 'bottom-right',
    duration: 3000,
  });
};

export const  objectToFormData = (object, formData = new FormData()) => {
  _.forEach(object, (value, field) => {
    if (typeof value === 'object' && value !== null && !(value instanceof File)) {
      value = JSON.stringify(value);
    }
    formData.append(field, value);
  });

  return formData;
};

export const buildSourceURL = (relativURL) => `${backURL}${relativURL}`;

export const roundNumber = value => +value?.toFixed(2);