import {
  SET_AUTHORS,
  DELETE_AUTHOR,
} from '../actions/author';

let initialState = {
  authorsData: [],
};

const author = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTHORS:
      return {
        ...state,
        authorsData: action.payload,
      };

    case DELETE_AUTHOR: {
      const { authorsData } = state;
      const newAuthorsData = authorsData.filter((authorData) => {
        return authorData.id !== action.payload;
      });

      return {
        ...state,
        authorsData: newAuthorsData,
      };
    }

    default:
      return state;
  }
};

export default author;
