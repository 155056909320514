import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import { logout } from '../../actions/auth';

import styles from './Header.module.css';

const Header = ({ logout }) => {
  return (
    <header className={styles.header}>
      <Link className={styles.logo} to="/">MealWell</Link>
      <nav className={styles.menuWrapper}>
        <ul className={styles.menu}>
          <li className={styles.menuItem}>
            <NavLink
              to="/"
              className={styles.menuLink}
            >
              Questioner
            </NavLink>
          </li>
          <li className={styles.menuItem}>
            <NavLink
              to="/remind-me"
              className={styles.menuLink}
            >
              Remind me
            </NavLink>
          </li>
          <li className={styles.menuItem}>
            <NavLink
              to="/strict-menu"
              className={styles.menuLink}
            >
              Strict menu
            </NavLink>
          </li>
          <li className={styles.menuItem}>
            <NavLink
              to="/allergies"
              className={styles.menuLink}
            >
              Allergies
            </NavLink>
          </li>
          <li className={styles.menuItem}>
            <NavLink
              to="/dishes"
              className={styles.menuLink}
            >
              Dishes
            </NavLink>
          </li>
          <li className={styles.menuItem}>
            <NavLink
              to="/dish-priorities"
              className={styles.menuLink}
            >
              Dish Priorities
            </NavLink>
          </li>
          <li className={styles.menuItem}>
            <NavLink
              to="/ingredients"
              className={styles.menuLink}
            >
              Ingredients
            </NavLink>
          </li>
          <li className={styles.menuItem}>
            <NavLink
              to="/mobile-users"
              className={styles.menuLink}
            >
              Mobile Users
            </NavLink>
          </li>
          <li className={styles.menuItem}>
            <NavLink
              to="/help"
              className={styles.menuLink}
            >
              Support
            </NavLink>
          </li>
          <li className={styles.menuItem}>
            <NavLink
              to="/deleted-users"
              className={styles.menuLink}
            >
              GDPR
            </NavLink>
          </li>
          <li className={styles.menuItem}>
            <NavLink
              to="/blog"
              className={styles.menuLink}
            >
              Blog
            </NavLink>
          </li>
          <li className={styles.menuItem}>
            <NavLink
              to="/authors"
              className={styles.menuLink}
            >
              Authors
            </NavLink>
          </li>
          <li className={styles.menuItem}>
            <NavLink
              to="/banners"
              className={styles.menuLink}
            >
              Banners
            </NavLink>
          </li>
          <li className={styles.menuItem}>
            <NavLink
              to="/settings"
              className={styles.menuLink}
            >
              Settings
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className={styles.logout} onClick={logout}>
        Logout
      </div>
    </header>
  );
};

const mapDispatchToProps = {
  logout,
};

export default connect(null, mapDispatchToProps)(Header);
