export const countries = {
  GB: 'United Kingdom',
  US: 'United States',
};

export const physicalActivityLabel = {
  passive: 'Just seat most of the time',
  moderate: 'Exercise 1-3 times per week',
  highly: 'Exercise 4-5 times per week',
  sportsmen: 'Professional sportsman',
};

export const goalLabel = {
  loose: 'Lose weight',
  balanced: 'Balanced my diet',
  gain: 'Gain weight',
};

export const weightUnitLabels = {
  kg: 'kg',
  lb: 'pounds',
  st: 'stones'
};

export const heightUnitLabels = {
  cm: 'cm',
  foot: 'foot',
  inch: 'inch'
};

const DAIRY = 'dairy';
const MEAT = 'meat';
const FISH = 'fish';
const EGGS = 'eggs';
const HIGH = 'high';
const LOW = 'low';
const VEGIE = 'vegie';

export const excludedMealsAdditionalDataLabels = {
  [DAIRY]: 'don\'t eat dairy',
  [MEAT]: 'don\'t eat meat',
  [FISH]: 'don\'t eat seafood and fish',
  [EGGS]: 'don\'t eat eggs',
  [HIGH]: 'have a high-calorie diet',
  [LOW]: 'have a low-calorie diet',
  [VEGIE]: 'is vegetarian',
};

export const excludedMealLabels = [
  {
    label: 'vegetarian',
    value: VEGIE,
  },
  {
    label: 'have a high-calorie diet',
    value: HIGH,
  },
  {
    label: 'have a low-calorie diet',
    value: LOW,
  },
  {
    label: 'don\'t eat dairy',
    value: DAIRY,
  },
  {
    label: 'don\'t eat meat',
    value: MEAT,
  },
  {
    label: 'don\'t eat seafood and fish',
    value: FISH,
  },
  {
    label: 'don\'t eat eggs',
    value: EGGS,
  },
];