import React from 'react';

import Menu from './Menu';
import styles from './Menus.module.css';


const Menus = ({ menus }) => {
  return (
    <div className={styles.footer}>
      {menus.map((menu, index) => {
        const dayString = `Day ${index + 1} / ${menus.length}`;
        return <Menu key={index} menu={menu} day={dayString} />;
      })}
    </div>
  );
};

export default Menus;