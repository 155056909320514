import { get, post, put, deleteById } from './api';

export const mobileUserAPI = {
  getMobileUsers() {
    return get('admin/mobile-user');
  },

  getMobileUserDetails(id) {
    return get(`admin/mobile-user/${id}`);
  },

  getMobileChatGPTDetails(id) {
    return get(`admin/mobile-chat-gpt/${id}`);
  },

  addToTest(id) {
    return post('admin/mobile-add-to-test', { id });
  },

  getChatGPTTest() {
    return get('admin/chat-gpt-tests');
  },

  getChatGPTTestDetails(id) {
    return get(`admin/chat-gpt-test/${id}`);
  },

  saveTest(promptData) {
    console.log('===SAVE TEST', promptData);
    return put('admin/chat-gpt-test', promptData);
  },

  runTest(id) {
    return post('admin/chat-gpt-run-test', { id });
  },

  clearAnswers(id) {
    return deleteById('admin/chat-gpt-clear-test', id);
  },

  getDeletedUsers() {
    return get('admin/deleted-users');
  }
};
