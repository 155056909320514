import {
  SET_BANNERS,
  DELETE_BANNER,
} from '../actions/banner';

let initialState = {
  bannersData: [],
};

const banner = (state = initialState, action) => {
  switch (action.type) {
    case SET_BANNERS:
      return {
        ...state,
        bannersData: action.payload,
      };

    case DELETE_BANNER: {
      const { bannersData } = state;
      const newBannersData = bannersData.filter((bannerData) => {
        return bannerData.id !== action.payload;
      });

      return {
        ...state,
        bannersData: newBannersData,
      };
    }

    default:
      return state;
  }
};

export default banner;
