import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';

import { addIngredient, updateIngredient, calculateNutrients } from '../../actions/ingredient';
import { unitTypes, UNIT_TYPE_WITHOUT_AMOUNT } from '../../constants/unitTypes';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import ImageInput from '../ImageInput/ImageInput';
import styles from './IngredientModal.module.css';

const IngredientModal = (props) => {
  const { 
    initialValue, 
    isOpen, 
    onClose,
    addIngredient,
    updateIngredient,
    ingredientsData,
    calculateNutrients,
    ingredientNutrients,
  } = props;

  const [name, setName] = useState('');  
  const [unitType, setUnitType] = useState('');
  const [unitGrams, setUnitGrams] = useState('');
  const [unitSale, setUnitSale] = useState('piece');
  const [packageWeight, setPackageWeight] = useState('');
  const [calories, setCalories] = useState('');
  const [fat, setFat] = useState('');
  const [protein, setProtein] = useState('');
  const [carbohydrates, setCarbohydrates] = useState('');  
  const [image, setImage] = useState('');

  useEffect(() => {
    if (initialValue) {
      console.log('===INIT', initialValue?.image);
      setName(initialValue?.name);
      setUnitType(initialValue?.unit_type);
      setUnitGrams(initialValue?.unit_grams);
      setUnitSale(initialValue?.unit_sale);
      setPackageWeight(initialValue?.package_weight);
      setCalories(initialValue?.calories);
      setFat(initialValue?.fat);
      setProtein(initialValue?.protein);
      setCarbohydrates(initialValue?.carbohydrates);
      setImage(initialValue?.image);
    } 
  }, [isOpen, initialValue, ingredientsData]);

  console.log('===IMAGE STATE', image);

  useEffect(() => {
    if (ingredientNutrients) {
      setCalories(ingredientNutrients.calories);
      setFat(ingredientNutrients.fat);
      setProtein(ingredientNutrients.protein);
      setCarbohydrates(ingredientNutrients.carbohydrates);
    }

  }, [ingredientNutrients]);

  const title = initialValue ? 'Edit ingredient' : 'Add new ingredient';

  const changeValueHandler = setter => event => {
    setter(event.target.value);
  };

  const changeImageHandler = (imageData) => {
    console.log('===CHANGE IMAGE', imageData);
    setImage(imageData);
  };

  console.log('===image', image);

  const onSaveHandler = () => {
    const trimmedName = name.trim();
    if (!trimmedName) {
      return toast.error('Name is required', {
        position: 'bottom-right',
        duration: 3000,
      });
    }

    if (!unitType) {
      return toast.error('Unit type is required', {
        position: 'bottom-right',
        duration: 3000,
      }); 
    }

    if (!unitSale) {
      return toast.error('Unit sale is required', {
        position: 'bottom-right',
        duration: 3000,
      }); 
    }

    if (!unitGrams) {
      return toast.error('Unit grams is required', {
        position: 'bottom-right',
        duration: 3000,
      }); 
    }

    // Check if there is an ingredient with the same name and unit type
    const existenceIngredient = ingredientsData.find(ingredient => {
      if (ingredient.name !== trimmedName) {
        return false;
      }

      return ingredient.unit_type === unitType && initialValue?.id !== ingredient.id;
    });

    if (existenceIngredient) {
      return toast.error('Ingredient with this name and unit type already exists', {
        position: 'bottom-right',
        duration: 3000,
      }); 
    }

    const ingredientData = {
      name: trimmedName,
      image,
      unit_type: unitType,
      unit_grams: unitGrams,
      unit_sale: unitSale,
    };

    if (unitSale === 'gram') {
      ingredientData.package_weight = packageWeight;
    }

    if (calories) {
      ingredientData.calories = calories;
    }

    if (fat) {
      ingredientData.fat = fat;
    }

    if (protein) {
      ingredientData.protein = protein;
    }

    if (carbohydrates) {
      ingredientData.carbohydrates = carbohydrates;
    }

    if (initialValue) {
      ingredientData.id = initialValue.id;
      updateIngredient(ingredientData);
    } else {
      addIngredient(ingredientData);
    }

    onClose();
  };

  const calculateNutrientsHandler = () => {
    const ingredientData = {
      name,
      unit_type: unitType,
    };
    calculateNutrients(ingredientData);
  };

  return (
    <ModalWrapper 
      key={initialValue?.id}
      isOpen={isOpen} 
      onClose={onClose} 
      onSave={onSaveHandler} 
      title={title}
    >
      <div className={styles.wrapper}>
        <label className={styles.row}>
          <span>Ingredient name</span>
          <input 
            className={styles.input} 
            onChange={changeValueHandler(setName)}
            placeholder="Ingredient name"
            value={name}
          />
        </label>
        <div className={styles.dishImages}>
          <div className={styles.dishImagesTitle}>
            Ingredient image
          </div>
          <ImageInput 
            key={initialValue?.id}
            value={image}
            onChange={changeImageHandler}
            name="image"
            label="Upload"
          />
        </div>
        <label className={styles.row}>
          <span>Unit type</span>
          <select 
            className={styles.select} 
            onChange={changeValueHandler(setUnitType)}
            value={unitType}
          >
            <option value="" disabled>Select unit type</option>
            {unitTypes.map((unitTypeData) => (
              <option key={unitTypeData.value} value={unitTypeData.value}>
                {unitTypeData.label}
              </option>
            ))}
          </select>
        </label>
        <label className={styles.row}>
          <span>Unit grams</span>
          <input 
            className={styles.input} 
            onChange={changeValueHandler(setUnitGrams)}
            placeholder="Unit grams"
            value={unitGrams}
            type="number"
          />
        </label>
        <label className={styles.row}>
          <span>Unit of sale</span>
          <select 
            className={styles.select} 
            onChange={changeValueHandler(setUnitSale)}
            value={unitSale}
          >
            <option value="piece">By piece</option>
            <option value="gram">In grams</option>
          </select>
        </label>
        {unitSale === 'gram' && (
          <label className={styles.row}>
            <span>Package weight</span>
            <input 
              className={styles.input} 
              onChange={changeValueHandler(setPackageWeight)}
              placeholder="Package weight"
              value={packageWeight}
              type="number"
            />
          </label>
        )}
        {unitType && unitType !== UNIT_TYPE_WITHOUT_AMOUNT && (
          <>
            <div className={styles.optional}>Optional:</div>
            <div className={styles.nutrients}>
              <label className={styles.row}>
                <span>Kcal</span>
                <input 
                  className={styles.input} 
                  onChange={changeValueHandler(setCalories)}
                  type="number"
                  placeholder="Kcal"
                  value={calories}
                />
              </label>
              <label className={styles.row}>
                <span>Fat</span>
                <input 
                  className={styles.input} 
                  onChange={changeValueHandler(setFat)}
                  type="number"
                  placeholder="Fat"
                  value={fat}
                />
              </label>
              <label className={styles.row}>
                <span>Protein</span>
                <input 
                  className={styles.input} 
                  onChange={changeValueHandler(setProtein)}
                  type="number"
                  placeholder="Protein"
                  value={protein}
                />
              </label>
              <label className={styles.row}>
                <span>Carbohydrates</span>
                <input 
                  className={styles.input} 
                  onChange={changeValueHandler(setCarbohydrates)}
                  type="number"
                  placeholder="Carbohydrates"
                  value={carbohydrates}
                />
              </label>
            </div>
            <button 
              className="button blue-button" 
              onClick={calculateNutrientsHandler}
              disabled={!name} 
            >
              Auto Calculate Nutrients
            </button>
          </>
        )}
      </div>
    </ModalWrapper>
  );
};

const mapStateToProps = (store) => ({
  ingredientsData: store.ingredient.ingredientsData,
  ingredientNutrients: store.ingredient.ingredientNutrients,
});

const mapDispatchToProps = {
  addIngredient,
  updateIngredient,
  calculateNutrients,
};

export default connect(mapStateToProps, mapDispatchToProps)(IngredientModal);