import { get, postFile, putFile, deleteById } from './api';

export const authorAPI = {
  getAuthors() {
    return get('admin/author');
  },

  addAuthor(authorData) {
    return postFile('admin/author', authorData);
  },

  updateAuthor(authorData) {
    return putFile('admin/author', authorData);
  },

  deleteAuthor(id) {
    return deleteById('admin/author', id);
  },
};
