import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import BlogForm from '../../components/BlogForm/BlogForm';

import { getBlogById, clearCurrentBlog, updateBlog } from '../../actions/blog';

const EditBlog = (props) => {
  const { 
    getBlogById,
    clearCurrentBlog,
    updateBlog, 
    blogData 
  } = props;

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getBlogById(params.id);
    return () => clearCurrentBlog();
  }, []);

  const updateBlogHandler = (newBlogData) => {
    newBlogData.id = blogData.id;
    updateBlog(newBlogData, () => {
      navigate('/blog');
    });
  };

  return (
    <div className="wrapper">
      <h1>Edit Blog</h1>
      {blogData && (
        <BlogForm 
          onSave={updateBlogHandler} 
          initialValues={blogData} 
        />
      )}
    </div>
  );
};

const mapStateToProps = (store) => ({
  blogData: store.blog.currentBlog,
});

const mapDispatchToProps = {
  getBlogById,
  clearCurrentBlog,
  updateBlog,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBlog);