import {
  SET_HELP,
} from '../actions/help';

let initialState = {
  helpData: [],
};

const help = (state = initialState, action) => {
  switch (action.type) {
    case SET_HELP:
      return {
        ...state,
        helpData: action.payload,
      };

    default:
      return state;
  }
};

export default help;
