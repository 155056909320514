import {
  SET_BLOGS,
  DELETE_BLOG,
  SET_CURRENT_BLOG,
} from '../actions/blog';

let initialState = {
  blogsData: [],
  currentBlog: null,
};

const blog = (state = initialState, action) => {
  switch (action.type) {
    case SET_BLOGS:
      return {
        ...state,
        blogsData: action.payload,
      };
      
    case SET_CURRENT_BLOG:
      return {
        ...state,
        currentBlog: action.payload,
      };

    case DELETE_BLOG: {
      const { blogsData } = state;
      const newBlogsData = blogsData.filter((blogData) => {
        return blogData.id !== action.payload;
      });

      return {
        ...state,
        blogsData: newBlogsData,
      };
    }

    default:
      return state;
  }
};

export default blog;
