import { get, postFile, putFile, deleteById } from './api';

export const bannerAPI = {
  getBanners() {
    return get('admin/banner');
  },

  addBanner(bannerData) {
    return postFile('admin/banner', bannerData);
  },

  updateBanner(bannerData) {
    return putFile('admin/banner', bannerData);
  },

  deleteBanner(id) {
    return deleteById('admin/banner', id);
  },
};
