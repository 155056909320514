import React, { useMemo } from 'react';
import _ from 'lodash';

import { 
  buildSourceURL, 
  roundNumber, 
  getIngredientAmountString, 
} from '../../utils/functions';
import styles from './Menus.module.css';

const Menu = ({ menu, day }) => {
  const totalNutrients = useMemo(() => {
    const nutrients = {};
    _.map(menu, (dish) => {
      _.map(dish.nutrients, (amount, nutrient) => {
        if (!nutrients[nutrient]) {
          nutrients[nutrient] = 0;
        }
        nutrients[nutrient] += amount;
      });
    });
    return nutrients;
  }, [menu]);

  const renderNutrients = (nutrients, prefix = '') => (
    <div className={styles.nutrients}>
      <div className={styles.nutrientRow}>
        <strong>{prefix} calories: </strong> 
        {roundNumber(nutrients.calories)} kcal<br />
      </div>
      <div className={styles.nutrientRow}>
        <strong>{prefix} fat: </strong> 
        {roundNumber(nutrients.fat)} grams<br />
      </div>
      <div className={styles.nutrientRow}>
        <strong>{prefix} carbohydrates: </strong> 
        {roundNumber(nutrients.carbohydrates)} grams<br />
      </div>
      <div className={styles.nutrientRow}>
        <strong>{prefix} proteins: </strong> 
        {roundNumber(nutrients.protein)} grams
      </div>
    </div>
  );

  const renderIngredients = (ingredients) => {
    return ingredients.map((ingredient, index) => (
      <div key={index} className={styles.ingredientItem}>
        {getIngredientAmountString(ingredient, ingredient.amount)}
      </div>
    ));
  };

  const renderDish = (dish, meal) => {
    return (
      <div key={dish.id} className={styles.body}>
        <div className={styles.imageBlock}>
          <img className={styles.image} src={buildSourceURL(dish.image)} />
        </div>
        <div className={styles.infoBlock}>
          <div className={styles.mealName}>
            {meal}
          </div>
          <a 
            className={styles.dishName} 
            href={`/edit-dish/${dish.id}`} 
            target="_blank"
            rel="noreferrer"
          >
            {dish.name}
          </a>
          {renderNutrients(dish.nutrients)}
          <div className={styles.title}>Ingredients:</div>
          <div className={styles.componentData}>
            {dish.components.map((component, index) => (
              <div key={index} className={styles.componentInfo}>
                <div className={styles.componentName}>
                  {component.name}
                </div>
                {renderIngredients(component.ingredients)}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.menuWrapper}>
      <div className={styles.header}>
        {day}
      </div>
      <div className={styles.totalNutrients}>
        {renderNutrients(totalNutrients, 'Total')}
      </div>
      {_.map(menu, renderDish)}
    </div>
  );
};

export default Menu;