// import toast from 'react-hot-toast';

import { bannerAPI } from '../api/banner';
import { actionWrapper } from './actionWrapper';
import { objectToFormData } from '../utils/functions';

export const SET_BANNERS = 'SET_BANNERS';
export const DELETE_BANNER = 'DELETE_BANNER';

export const getBanners = () => actionWrapper(async () => {
  const bannersData = await bannerAPI.getBanners();

  return {
    type: SET_BANNERS,
    payload: bannersData,
  };
});

export const addBanner = (bannerData) => actionWrapper(async (dispatch) => {
  const bannerFormData = objectToFormData(bannerData);
  await bannerAPI.addBanner(bannerFormData);
  dispatch(getBanners());
});

export const updateBanner = (bannerData) => actionWrapper(async (dispatch) => {
  const bannerFormData = objectToFormData(bannerData);
  await bannerAPI.updateBanner(bannerFormData);
  dispatch(getBanners());
});

export const deleteBanner = (id) => actionWrapper(async () => {
  await bannerAPI.deleteBanner(id);

  return {
    type: DELETE_BANNER,
    payload: id,
  };
});
