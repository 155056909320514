import React, { useState } from 'react';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';

import { addAuthor, updateAuthor } from '../../actions/author';
import ImageInput from '../ImageInput/ImageInput';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import styles from './AuthorModal.module.css';

const AuthorModal = (props) => {
  const { 
    initialValue, 
    isOpen, 
    onClose,
    addAuthor,
    updateAuthor,
  } = props;

  const [name, setName] = useState(initialValue?.name || '');
  const [profession, setProfession] = useState(initialValue?.profession || '');
  const [image, setImage] = useState(initialValue?.image || '');

  const title = initialValue ? 'Edit author' : 'Add new author';

  const changeValueHandler = setter => event => {
    setter(event.target.value);
  };

  const onSaveHandler = () => {
    const trimmedName = name.trim();
    const trimmedProfession = profession.trim();
    if (!trimmedName) {
      return toast.error('Name is required', {
        position: 'bottom-right',
        duration: 3000,
      });
    }

    if (!trimmedProfession) {
      return toast.error('Profession is required', {
        position: 'bottom-right',
        duration: 3000,
      }); 
    }

    if (!image) {
      return toast.error('Image is required', {
        position: 'bottom-right',
        duration: 3000,
      }); 
    }

    const authorData = {
      name: trimmedName,
      profession: trimmedProfession,
      image,
    };

    if (initialValue) {
      authorData.id = initialValue.id;
      updateAuthor(authorData);
    } else {
      addAuthor(authorData);
    }

    onClose();
  };

  return (
    <ModalWrapper 
      isOpen={isOpen} 
      onClose={onClose} 
      onSave={onSaveHandler} title={title}
    >
      <div className={styles.wrapper}>
        <label className={styles.row}>
          <span>Author name</span>
          <input 
            className={styles.input} 
            onChange={changeValueHandler(setName)}
            placeholder="Author name"
            value={name}
          />
        </label>
        <label className={styles.row}>
          <span>Profession</span>
          <input 
            className={styles.input} 
            onChange={changeValueHandler(setProfession)}
            placeholder="Profession"
            value={profession}
          />
        </label>
        <label>
          <ImageInput 
            value={image}
            onChange={setImage}
            name="author"
          />
        </label>
      </div>
    </ModalWrapper>
  );
};

const mapDispatchToProps = {
  addAuthor,
  updateAuthor,
};

export default connect(null, mapDispatchToProps)(AuthorModal);