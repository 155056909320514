import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { backURL } from '../../config';


import styles from './ImageInput.module.css';

const ImageInput = (props) => {
  const { 
    name, 
    value, 
    onChange, 
    label,
  } = props;

  const id = `image-${name}-preview`;

  const [selectedImage, setSelectedImage] = useState(value);
  const [imageSrc, setImageSrc] = useState(value);

  useEffect(() => {
    if (selectedImage) {
      if (typeof selectedImage !== 'string') {
        let reader = new FileReader();
        reader.onload = () => {
          setImageSrc(reader.result);
        };
        reader.readAsDataURL(selectedImage);
      } else {
        const imageURL = `${backURL}${value}`;
        setImageSrc(imageURL);
      }
    }
  }, [selectedImage]);

  useEffect(() => {
    setSelectedImage(value);
  }, [value]);

  const deleteImageHandler = () => {
    setSelectedImage(null);
    onChange('');
  };

  const changeHandler = (event) => {
    // props.input.onChange(e.target.files);
    if (event.target.files.length) {
      // console.log('===event.target.files[0]', event.target.files[0]);
      setSelectedImage(event.target.files[0]);
      onChange(event.target.files[0]);
    }
  };

  // console.log('===selectedImage', selectedImage);

  return (
    <div>
      <div className={cn(!!selectedImage && styles.hidden)}>
        <label className={styles.imageButton} htmlFor={id}>{label}</label>
        <input 
          className={styles.hidden} 
          id={id} 
          name={name}
          type="file"
          accept=".jpg, .jpeg, .png"
          onChange={changeHandler} 
        />
      </div>
      {selectedImage && (
        <div className={styles.imageWrapper}>
          <div 
            className={styles.closeImage}
            onClick={deleteImageHandler} 
          />
          <img
            id={id}
            className={styles.imagePreview}
            src={typeof imageSrc === 'string' && imageSrc}
            alt="Preview"
          />
        </div>
      )}
    </div>
  );
};

export default ImageInput;
