import { helpAPI } from '../api/help';
import { actionWrapper } from './actionWrapper';

export const SET_HELP = 'SET_HELP';

export const getHelp = () => actionWrapper(async () => {
  const dishesData = await helpAPI.getHelp();

  return {
    type: SET_HELP,
    payload: dishesData,
  };
});
