import { get, postFile, put, putFile, deleteById } from './api';

export const ingredientAPI = {
  getIngredients() {
    return get('admin/ingredient');
  },

  addIngredient(ingredientData) {
    return postFile('admin/ingredient', ingredientData);
  },

  updateIngredient(ingredientData) {
    return putFile('admin/ingredient', ingredientData);
  },

  deleteIngredient(id) {
    return deleteById('admin/ingredient', id);
  },

  calculateAllNutrients() {
    return put('admin/ingredient/calculate-all-nutrients');
  },

  calculateNutrients(data) {
    return put('admin/ingredient/calculate-nutrients', data);
  },
};
